<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-card class="mx-auto" elevation="5" tile flat width="100%" max-width="500px" style="">
        <v-toolbar color="primary" dark>
          <v-container>
            <v-row class="justify-center align-center">
              <img :src="logo" style="max-height: 60px">
            </v-row>
          </v-container>
        </v-toolbar>

        <v-card-text align="center">
          <v-form @submit.prevent="onSubmit" v-model="isValid">
            <v-text-field
              v-model.trim="username"
              :rules="[rules.required]"
              name="username"
              label="E-mail"
            ></v-text-field>

            <v-text-field
              v-model.trim="password"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              name="password"
              label="Password"
            ></v-text-field>

            <v-btn class="ma-2" color="primary" type="submit" :disabled="!isValid">
              <v-icon left>mdi-login</v-icon>
              Sign in
            </v-btn>

          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="ma-2" color="success" small to="/registration">
            <v-icon left>mdi-account-check</v-icon>
            Pre-registration
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" color="warning" small to="/forgot_password">
            <v-icon>mdi-biohazard</v-icon>
            Forgotten password
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-layout>
  </v-container>

</template>

<script>
import logo from '@/assets/mk-logo-white.svg'

export default {
  data () {
    return {
      logo,
      username: null,
      password: null,
      isValid: false,
      showPassword: false,
      rules: {
        required: value => !!value || 'Required field'
      }
    }
  },
  methods: {
    async onSubmit () {
      try {
        await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password
        })
      } catch (e) {
      }
    }
  },
  async mounted () {
    document.title = 'Sign in | Masarka Student Club'
  }
}
</script>
